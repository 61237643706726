/* General Styles */
body {
    background-color: #f8f9fa;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
}

/* Styles for the main container */
.results-container {
    width: 100vw !important;
    max-width: 100% !important;
    margin: 0 !important;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

/* New layout for left sidebar and main content */
.results-layout {
    display: flex;
}

/* Sidebar styles */
.left-side-panel {
    width: 300px;
    padding: 20px;
    border-right: 1px solid #dee2e6;
}

/* Main content styles */
.results-content {
    width: 100%;
    flex: 1;
    flex-shrink: 1;
    max-width: 1200px;
    /* Optional: set a max width */
    margin: 0 auto;
    /* Center the content */
    padding: 20px;
    /* Optional: add padding */
    box-sizing: border-box;
    /* Ensure padding is included in width calculation */
}

/* Headings and Text */
.results-heading {
    text-align: center;
    color: #343a40;
    margin-bottom: 20px;
    font-size: 2rem;
}

/* Buttons and Links */
.results-download-btn {
    text-align: center;
    margin-bottom: 20px;
}

.results-btn-download {
    background-color: #007bff;
    color: #ffffff;
    padding: 10px 25px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.results-btn-download:hover {
    background-color: #0056b3;
}

/* Table Styling */
.results-scrollable-table-container {
    max-height: 450px;
    overflow: auto;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
}

.top-correlations-container {
    margin-bottom: 30px;
}

.greyed-out {
    opacity: 0.5;
    /* Makes the container appear greyed out */
    pointer-events: none;
    /* Disables mouse interactions */
    background-color: rgba(255, 255, 255, 0.7);
    /* Optional: adds a slight white overlay */
}

.results-scrollable-table-container table {
    width: 100%;
    border-collapse: collapse;
}

.results-scrollable-table-container th,
.results-scrollable-table-container td {
    padding: 10px 15px;
    border: 1px solid #dee2e6;
    text-align: left;
}

.results-scrollable-table-container th {
    background-color: #e9ecef;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
}

/* Custom Scrollbar */
.results-scrollable-table-container::-webkit-scrollbar {
    width: 10px;
}

.results-scrollable-table-container::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
}

.results-scrollable-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
}

/* Highlighted and Dimmed States */
.results-dimmed {
    opacity: 0.6;
}

/* Toggle container styling */
.toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/* Switch styling */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-right: 10px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.toggle-slider {
    background-color: #007bff;
}

input:checked+.toggle-slider:before {
    transform: translateX(26px);
}

/* Additional Styles for Matrix Header */
.matrix-header-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.matrix-title {
    font-size: 24px;
    color: #343a40;
}

.matrix-description {
    margin: 10px 0;
    font-size: 1rem;
    color: #6c757d;
}

.question-icon {
    margin-left: 8px;
    cursor: pointer;
    color: #007bff;
    transition: color 0.3s;
}

.question-icon:hover {
    color: #0056b3;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
    .results-layout {
        flex-direction: column;
        /* Stack items vertically on mobile */
    }

    .results-content {
        padding: 10px;
        /* Optional: reduce padding on mobile */
    }

    .left-side-panel {
        width: 100%;
        /* Full width on mobile */
        border-right: none;
        /* Remove right border */
        border-top: 1px solid #dee2e6;
        /* Add top border for separation */
    }
}

.results-markdown-container {
    margin-top: 20px;
    /* Increased margin for spacing above */
    font-size: 16px;
    /* Keep the same font size */
    color: #333;
    /* Text color */
    background-color: #e0f7fa;
    /* Light background color for contrast */
    border-left: 4px solid #ddd;
    /* Vertical line to the left */
    padding: 10px 15px;
    /* Padding around the text */
    margin: 0 0 1em 0;
    /* Margin for spacing below */
    border-radius: 5px;
    /* Optional: slightly rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: subtle shadow */
}

.results-markdown-container blockquote {
    color: #6c757d;
    /* Text color for quotes */
}