/* Question icon styling */
.question-icon {
    margin-left: 8px;
    /* Space between title and icon */
    cursor: pointer;
    color: #007bff;
    /* Match the primary color */
    transition: color 0.3s;
    /* Smooth color transition */
}

/* Change color on hover */
.question-icon:hover {
    color: #0056b3;
    /* Darken the icon on hover */
}

/* Container for the header with title and selector */
.top-correlations-header-container {
    display: flex;
    flex-direction: column;
    /* Change to column layout */
    margin-bottom: 20px;
}

/* Title styling */
.top-correlations-title {
    font-size: 24px;
    color: #343a40;
}

/* Container for the top-N selector */
.top-correlations-top-n-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    /* Add spacing above the selector */
}

/* Label styling */
.top-correlations-top-n-selector label {
    font-weight: 500;
    color: #495057;
}

/* Styling for the dropdown */
.top-correlations-form-select {
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    transition: border-color 0.3s;
    /* Smooth border color transition */
}

/* Focus state styling for the dropdown */
.top-correlations-form-select:focus {
    border-color: #007bff;
    /* Highlight border on focus */
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    /* Box shadow for focus */
}

/* Description styling */
.top-correlations-description {
    margin: 10px 0;
    /* Space around description */
    font-size: 1rem;
    /* Font size for description */
    color: #6c757d;
    /* Optional: lighter color for description */
}

/* Add space between dropdown and table */
.top-correlations-scrollable-table-container {
    margin-top: 20px;
    /* Space between dropdown and table */
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Styling for the table */
.top-correlations-table {
    width: 100%;
    border-collapse: collapse;
}

/* Table cell styling */
.top-correlations-table th,
.top-correlations-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
}

/* Header cell styling */
.top-correlations-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #495057;
}

/* Row styling for clickable rows */
.top-correlations-clickable-row {
    cursor: pointer;
    transition: background-color 0.3s;
    /* Smooth background color transition */
}

/* Hover effect for clickable rows */
.top-correlations-clickable-row:hover:not(.top-correlations-selected) {
    background-color: rgba(0, 123, 255, 0.1);
    /* Highlight on hover */
}

/* Styling for selected rows */
.top-correlations-selected {
    background-color: #007bff;
    /* Highlight color for selected rows */
    color: white;
    /* Text color for selected rows */
}

/* Add these to TopCorrelationsTable.css */
.correlation-value.lightest {
    color: #b0b0b0;
    /* light gray */
}

.correlation-value.light {
    color: #707070;
    /* darker gray */
}

.correlation-value.strong {
    color: #000000;
    /* black or strong color */
}