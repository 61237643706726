/* Container styling for non-numeric (categorical) columns */
.non-numeric-columns-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    /* White background */
    border-radius: 10px;
    /* Rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

/* Heading styling */
.non-numeric-columns-heading {
    margin-bottom: 15px;
    font-size: 14px;
    /* Changed to 14px */
    color: #2c3e50;
    /* Darker text color */
    text-align: center;
    /* Center the heading */
}

/* Paragraph styling */
.non-numeric-columns-paragraph {
    margin-bottom: 20px;
    font-size: 14px;
    /* Remains at 14px */
    color: #7f8c8d;
    /* Medium gray for the paragraph */
    text-align: center;
    /* Center the paragraph text */
}

/* List group container for the checkboxes */
.non-numeric-columns-list-group {
    display: grid;
    /* Use grid layout */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    /* Responsive columns */
    gap: 15px;
    /* Space between items */
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
}

/* Individual list group items */
.non-numeric-columns-list-item {
    display: flex;
    /* Use flex to align items */
    align-items: center;
    /* Center vertically */
    padding: 10px 15px;
    /* Padding for the items */
    border-radius: 8px;
    /* Rounded corners */
    background-color: #f9f9f9;
    /* Light gray background */
    border: 1px solid #e0e0e0;
    /* Light border */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transitions */
    cursor: pointer;
    /* Pointer cursor for interactivity */
}

/* Hover effect for list items */
.non-numeric-columns-list-item:hover {
    background-color: #e0f7fa;
    /* Light blue on hover */
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    /* Slight shadow on hover */
}

/* Checkbox styling */
.non-numeric-columns-checkbox {
    margin-right: 10px;
    /* Space between checkbox and label */
    cursor: pointer;
    /* Pointer for checkboxes */
    font-size: 14px;
}

/* Label styling */
.non-numeric-columns-label {
    color: #34495e;
    /* Darker label color */
    font-weight: 500;
    /* Medium weight for labels */
    font-size: 14px;
}

/* Select/Deselect All Button */
.non-numeric-columns-select-all-btn {
    background-color: #007bff;
    /* Primary color */
    color: white;
    /* White text */
    border: none;
    /* Remove border for cleaner look */
    border-radius: 4px;
    /* Rounded corners */
    padding: 10px 15px;
    /* Padding for button */
    cursor: pointer;
    /* Pointer cursor for button */
    font-size: 14px;
    /* Changed to 14px */
    transition: background-color 0.3s;
    /* Smooth transition */
    margin-bottom: 10px;
    /* Add some spacing below the button */
    display: block;
    /* Make it a block element */
    width: 100%;
    /* Full width */
}

/* Hover effect for the select/deselect all button */
.non-numeric-columns-select-all-btn:hover {
    background-color: #0056b3;
    /* Darker blue on hover */
}

/* Styling for the selected state of the button */
.non-numeric-columns-select-all-btn.selected {
    background-color: #28a745;
    /* Success color for selected */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .non-numeric-columns-heading {
        font-size: 14px;
        /* Ensure it stays at 14px */
    }

    .non-numeric-columns-paragraph {
        font-size: 14px;
        /* Remains at 14px */
    }

    .non-numeric-columns-list-item {
        padding: 8px 12px;
        /* Smaller padding for items */
    }

    .non-numeric-columns-select-all-btn {
        font-size: 14px;
        /* Remains at 14px */
    }
}

/* Question icon styling */
.question-icon {
    margin-left: 8px;
    /* Space between title and icon */
    cursor: pointer;
    color: #007bff;
    /* Match the primary color */
    transition: color 0.3s;
    /* Smooth color transition */
}

/* Change color on hover */
.question-icon:hover {
    color: #0056b3;
    /* Darken the icon on hover */
}