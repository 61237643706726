/* Main container styling for the Processing page */
.processing-page-body {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f7fa;
    /* Light Gray Background */
    margin: 0;
    padding: 20px;
    /* Adds some space around the main body */
}

/* Container for the processing section */
.processing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 40px 30px;
    /* Adjust padding for a balanced look */
    border-radius: 16px;
    /* Slightly rounded corners for a modern look */
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    /* Softer shadow for depth */
    max-width: 450px;
    /* Adjusted max-width for better layout */
    width: 100%;
    margin: 20px;
    /* Margin to create space around the container */
    text-align: center;
}

/* Heading styling */
.processing-page-heading {
    margin-bottom: 20px;
    font-size: 24px;
    color: #343a40;
    /* Dark Gray */
    font-weight: 600;
    /* Slightly bolder heading */
}

/* Paragraph styling */
.processing-page-paragraph {
    margin-bottom: 20px;
    color: #666;
    /* Light Gray for instructions */
    font-size: 16px;
    line-height: 1.5;
    /* Better readability */
}

/* Spinner styling */
.processing-page-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    /* Space around spinner */
    border: 6px solid #f3f3f3;
    /* Light Gray */
    border-top: 6px solid #007bff;
    /* Bright Blue */
    border-radius: 50%;
    /* Circular shape */
    width: 50px;
    height: 50px;
    animation: processing-page-spin 1s linear infinite;
    /* Spin animation */
}

/* Keyframes for spinner animation */
@keyframes processing-page-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Warning message styling */
.processing-page-warning-message {
    color: #e74c3c;
    /* Red for warning messages */
    margin-top: 10px;
    font-size: 14px;
}