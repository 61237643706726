/* Newsletter Subscription Box Styling */
.newsletter-subscription {
    text-align: center;
    /* Center align the content */
    padding: 15px;
    /* Reduce padding around the subscription box */
    background-color: #2c2c2c;
    /* Dark gray background to blend with footer */
    border-radius: 8px;
    /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    /* Subtle shadow for depth */
}

.newsletter-subscription h4 {
    margin-bottom: 10px;
    /* Reduce space below the heading */
    color: #ffffff;
    /* White color for the heading */
}

.newsletter-subscription input[type="email"] {
    padding: 8px;
    /* Reduce padding inside the input */
    border: 1px solid #555;
    /* Darker gray border */
    border-radius: 5px;
    /* Slightly rounded corners */
    width: 70%;
    /* Width for input */
    max-width: 300px;
    /* Max width */
    margin-right: 10px;
    /* Space between input and button */
    transition: border-color 0.3s;
    /* Transition for border color */
}

.newsletter-subscription input[type="email"]:focus {
    border-color: #007BFF;
    /* Change border color on focus */
    outline: none;
    /* Remove default outline */
}

.newsletter-subscription button {
    padding: 8px 12px;
    /* Reduce button padding */
    border: none;
    /* No border */
    border-radius: 5px;
    /* Rounded corners */
    background-color: #007BFF;
    /* Primary color */
    color: white;
    /* Text color */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: background-color 0.3s;
    /* Transition for background color */
    margin-top: 8px;
    /* Adjust space above the button */
}

.newsletter-subscription button:hover {
    background-color: #0056b3;
    /* Darker shade for hover */
}

/* Existing styles */
.newsletter-subscription p {
    margin: 8px 0;
    /* Reduce space above and below */
    color: #999;
    /* Use a lighter gray for the subtitle */
    font-size: 14px;
    /* Font size for the subtitle */
}