/* Global Styles */
* {
    box-sizing: border-box;
}

/* Hero Section Styles */
.hero-section {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    overflow: hidden;
    /* Prevent overflow */
    padding: 0;
    margin: 0;
}

/* Overlay Styles */
.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

/* Heading Styles */
.hero-heading {
    font-size: 48px;
    margin-bottom: 10px;
}

/* Subheading Styles */
.hero-subheading {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Button Container Styles */
.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

/* Button Styles */
.hero-cta-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

/* Button Hover Styles */
.hero-cta-btn:hover {
    background-color: #0056b3;
}

/* Note Styles */
.hero-note {
    margin-top: 10px;
    font-size: 14px;
    color: #ddd;
}

/* Image Roll Container Styles */
.image-roll-container {
    display: flex;
    flex-direction: column;
    /* Stack rolls vertically */
    width: 100%;
    height: 100%;
}

/* Image Roll Styles */
.image-roll {
    display: flex;
    overflow: hidden;
    /* Prevent horizontal scrollbar */
    height: 100%;
    /* Full height for each roll */
    width: calc((368px + 8px) * 30);
    /* width of a picture + gap * 30 pictures in an image roll */
    gap: 8px;
    /* Add horizontal gap between images */
    margin-bottom: 8px;
    /* Margin below each image roll */
    animation: scroll-left 240s linear infinite;
    /* Continuous scrolling effect */
}

.image-roll:nth-child(2) {
    animation: scroll-left 180s linear infinite;

}

/* Image Item Styles */
.image-item {
    height: 100%;
    /* Max height to fill the image roll */
    overflow: hidden;
    /* Hide overflow */
    background-color: black;
    /* Black background for gap effect */
    border-radius: 8px;
    /* Rounded corners */
    flex: 0 0 auto;
    /* Prevent flex items from shrinking */
}

/* Image Styles */
.image-item img {
    height: 100%;
    /* Set the height to 100% of the parent */
    width: auto;
    /* Maintain the aspect ratio */
    object-fit: cover;
    /* Scale images to fit within their container */
    display: block;
    /* Prevent extra space below images */
    border-radius: 8px;
    /* Ensure images also have rounded corners */
}

/* Keyframe Animation for scrolling */
@keyframes scroll-left {
    0% {
        transform: translateX(0);
        /* Start position */
    }

    100% {
        transform: translateX(-50%);
        /* Move left by the total width */
    }
}