/* Main body styling for the Landing Page */
.landing-page-body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f7fa;
    /* Light Gray Background */
}

.landing-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Keep this */
    min-height: 100vh;
    /* Full height for landing */
    text-align: center;
    /* Center text */
    padding: 0 20px;
    /* Remove top padding, keep left and right padding */
}


/* Features section styling */
.landing-page-features {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Space between features */
    justify-content: center;
    /* Center align features */
    margin-top: 50px;
    /* Space above features section */
}

/* Flip effect styling */
.landing-page-feature {
    perspective: 1000px;
    /* 3D perspective for flipping effect */
    width: 320px;
    height: 220px;
    position: relative;
    cursor: pointer;
}

.landing-page-feature>div {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    border-radius: 12px;
    padding: 25px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
}

.feature-card-front {
    background-color: #f1f3f5;
    /* Lightest shade of blue */
    color: #2c3e50;
    z-index: 2;
    display: flex;
    flex-direction: column;
    /* Changed to column */
    align-items: center;
    /* Center align items */
    justify-content: center;
}

.feature-card-back {
    background-color: #007bff;
    color: white;
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Flipping logic: applying transformation on flip */
.landing-page-feature.flipped .feature-card-front {
    transform: rotateY(180deg);
}

.landing-page-feature.flipped .feature-card-back {
    transform: rotateY(0deg);
}

/* Feature heading styling */
.landing-page-feature-heading {
    font-size: 22px;
    /* Feature heading size */
    margin-bottom: 8px;
    /* Adjusted for better spacing */
    font-weight: 600;
}

/* Feature paragraph styling */
.landing-page-feature-paragraph {
    font-size: 16px;
    color: #777;
    /* Lighter gray for front side text */
    line-height: 1.5;
    text-align: center;
    /* Center text for better readability */
}

/* Back card text styling */
.landing-page-feature .feature-card-back .landing-page-feature-paragraph {
    color: #ffffff;
    /* White text on back card */
}

/* Carousel Demo Section */
.carousel-demo {
    margin-top: 50px;
    text-align: center;
    /* Center the text */
    width: 100%;
    /* Ensuring full width */
    max-width: 1200px;
    /* Optional: setting a maximum width */
}

.carousel-demo h2 {
    font-size: 28px;
    /* Style the section heading */
    margin-bottom: 20px;
    /* Space below heading */
}

/* Ensuring the slider is visible */
.slick-slide {
    display: flex !important;
    /* Ensuring slides are displayed */
    align-items: center;
    /* Center align items in slide */
    justify-content: center;
    /* Center justify items */
    height: auto;
    /* Allow height to adjust based on content */
    background: transparent;
    /* Remove background color */
    border: none;
    /* Remove any borders */
    padding: 20px;
    /* Add padding for better spacing */
}

/* Styling for each demo slide */
.demo-slide {
    display: flex;
    flex-direction: column;
    /* Stack image above text */
    align-items: center;
    /* Center align content */
    justify-content: center;
    /* Center content */
    text-align: center;
    /* Center text */
    padding: 20px;
    /* Padding for space */
}

.demo-slide img {
    max-width: 100%;
    /* Ensure images are responsive */
    border-radius: 12px;
    /* Optional: rounded corners for images */
    margin-bottom: 10px;
    /* Space between image and text */
}

.demo-slide h3 {
    color: #2c3e50;
    /* Dark color for headings */
    margin: 10px 0;
    /* Margin above and below */
}

.demo-slide p {
    color: #777;
    /* Gray color for description */
    line-height: 1.5;
    /* Improved line spacing */
}

/* Full-screen loading overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

/* Spinner Animation */
.spinner {
    width: 50px;
    height: 50px;
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Spinner Keyframes */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Hide HeroSection while loading */
.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
}