/* AboutPage.css */

/* Main container for the About Page */
.about-page {
    font-family: 'Roboto', sans-serif;
    margin: 20px auto;
    padding: 20px;
    max-width: 900px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* Grid layout for side-by-side elements */
    gap: 20px;
    /* Space between columns */
}

/* Profile section styling */
.profile-section {
    display: flex;
    justify-content: center;
    align-items: center;
    /* Center vertically */
}

/* Image container for hover effect */
.image-container {
    position: relative;
    /* Positioning context for images */
}

/* Profile image styling */
.profile-image {
    width: 200px;
    /* Set specific width */
    height: 200px;
    /* Set specific height */
    border-radius: 50%;
    /* Make it a circle */
    border: 3px solid #007bff;
    /* Optional border for emphasis */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    /* Shadow for depth */
    transition: opacity 0.3s ease;
    /* Smooth transition */
}

/* Hover image styling */
.hover-image {
    width: 200px;
    /* Same size as the profile image */
    height: 200px;
    /* Same size as the profile image */
    border-radius: 50%;
    /* Make it a circle */
    border: 3px solid #007bff;
    /* Optional border for emphasis */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    /* Shadow for depth */
    position: absolute;
    /* Position on top of the profile image */
    top: 0;
    /* Align to the top */
    left: 0;
    /* Align to the left */
    opacity: 0;
    /* Initially hidden */
    transition: opacity 0.3s ease;
    /* Smooth transition */
}

/* Show the hover image on hover */
.image-container:hover .hover-image {
    opacity: 1;
    /* Show hover image */
}

.image-container:hover .profile-image {
    opacity: 0;
    /* Hide profile image */
}

/* Text section styling */
.text-section {
    text-align: left;
    /* Align text to the left */
}

/* Heading styling */
.text-section h1 {
    font-size: 32px;
    color: #2c3e50;
    margin-bottom: 15px;
}

/* Paragraph styling */
.text-section p {
    font-size: 18px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Responsive adjustments */
@media (max-width: 600px) {

    .profile-image,
    .hover-image {
        width: 120px;
        /* Adjusted for mobile */
        height: 120px;
        /* Adjusted for mobile */
    }

    .text-section h1 {
        font-size: 24px;
        /* Smaller heading on mobile */
    }

    .text-section p {
        font-size: 16px;
        /* Smaller paragraph on mobile */
    }
}

/* Back link styling */
.back-link {
    margin-top: 20px;
    /* Add some spacing above the link */
}

.back-link a {
    text-decoration: none;
    /* Remove underline */
    color: #007bff;
    /* Match the color with the border color of images */
    font-size: 18px;
    /* Match the font size with the paragraph text */
    font-weight: bold;
    /* Make the text bold */
    transition: color 0.3s;
    /* Smooth transition for hover */
}

.back-link a:hover {
    color: #0056b3;
    /* Darker shade on hover for consistency */
}