/* Demo.css */

.demo-section {
    display: flex;
    /* Use flexbox for horizontal alignment */
    flex-direction: column;
    /* Stack title on top */
    justify-content: flex-start;
    /* Align items at the start */
    padding: 20px;
    /* Space inside the section */
    max-width: 1200px;
    /* Optional: limit the max width */
    margin: 40px auto;
    /* Add margin above and below for spacing */
    border: none;
    /* Remove any borders */
    border-radius: 0;
    /* Remove rounded corners */
    box-shadow: none;
    /* Remove shadow */
    background-color: transparent;
    /* Make the background transparent */
}

.demo-title {
    text-align: center;
    /* Center the title text */
    font-size: 24px;
    /* Title font size */
    font-weight: bold;
    /* Bold title */
    color: #333;
    /* Dark color for text */
    margin-bottom: 20px;
    /* Space below the title */
}

.demo-content {
    display: flex;
    /* Use flexbox for horizontal alignment */
    justify-content: space-between;
    /* Space items evenly */
    width: 100%;
    /* Ensure full width */
}

/* Mobile specific styles */
@media (max-width: 768px) {
    .demo-content {
        flex-direction: column;
        /* Stack the GIF above the list items on mobile */
        align-items: center;
        /* Center child elements */
    }

    .demo-list {
        max-width: 400px;
        /* Set a max width for the list */
        margin: 20px 0;
        /* Center the list with margin above and below */
        width: 100%;
        /* Full width on small screens */
    }

    .demo-video {
        margin-bottom: 20px;
        /* Optional spacing below GIF */
        width: 100%;
        /* Ensure GIF takes full width */
    }
}

.demo-list {
    flex: 1;
    /* Allow this section to grow */
    max-width: 300px;
    /* Limit the maximum width of the list */
    padding: 10px;
    /* Adjusted padding to reduce space around the list */
    margin: 0;
    /* Remove margin to bring the elements closer */
}

.demo-video {
    flex: 1;
    /* Allow this section to grow */
    padding: 10px;
    /* Added padding for a little spacing */
    display: flex;
    /* Use flexbox for centering */
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    border-radius: 0;
    /* Remove rounded corners */
    overflow: hidden;
    /* Prevent overflow */
}

/* Style for the GIF */
.demo-video img {
    width: 100%;
    /* Full width to fill the box */
    height: auto;
    /* Set height to auto to maintain aspect ratio */
    max-height: 100%;
    /* Limit height to prevent overflowing */
    object-fit: cover;
    /* Crop the GIF while maintaining aspect ratio */
    border: none;
    /* Ensure no border around the GIF */
    outline: none;
    /* Remove outline to prevent any extra borders */
    display: block;
    /* Prevent any extra space */
}

/* List styles */
.demo-list ul {
    list-style: none;
    /* Remove default bullet points */
    padding: 0;
    /* Remove default padding */
}

.demo-list li {
    margin-bottom: 10px;
    /* Space between list items */
    cursor: pointer;
    /* Show pointer on hover */
}

/* Styles for the list item containers */
.list-item-container {
    padding: 10px;
    /* Space inside list item */
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 5px;
    /* Slightly rounded corners */
    transition: background-color 0.3s ease, border-color 0.3s ease;
    /* Smooth transitions */
}

/* Hover effect */
.list-item-container:hover {
    background-color: #e0e0e0;
    /* Light gray background on hover */
    border-color: #888;
    /* Darker border color on hover */
}

/* Selected effect */
.selected {
    background-color: #cce5ff;
    /* Light blue background when selected */
    border-color: #007bff;
    /* Blue border when selected */
}

/* Styles for the title and description */
.list-item-title {
    font-weight: bold;
    /* Make the title bold */
    font-size: 18px;
    /* Title font size */
}

.list-item-description {
    font-size: 14px;
    /* Font size for the description */
    color: #666;
    /* Lighter color for the description text */
}

/* Responsive styles */
@media (max-width: 768px) {
    .demo-section {
        flex-direction: column;
        /* Stack items on small screens */
    }

    .demo-list,
    .demo-video {
        width: 100%;
        /* Full width on small screens */
        height: auto;
        /* Allow height to adjust */
    }
}