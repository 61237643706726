/* Footer.css */
.footer-business {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 40px 20px;
  text-align: left;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

/* New style for the newsletter subscription section */
.newsletter-container {
  flex-basis: 100%;
  /* Full width for the newsletter section */
  margin-bottom: 20px;
  /* Space below the newsletter subscription */
  text-align: center;
  /* Center the content of the subscription box */
}

.footer-section {
  flex: 1;
  min-width: 200px;
  margin: 10px;
}

.footer-section h4 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #007bff;
}

.footer-copyright {
  flex-basis: 100%;
  /* Ensure copyright text takes full width */
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Footer.css */
.footer-icon {
  margin-right: 8px;
  /* Adds space between the icon and text */
  color: #007bff;
  /* Match the color to the brand's primary color */
  transition: color 0.3s;
}

.footer-icon:hover {
  color: #0056b3;
  /* Darker blue on hover */
}