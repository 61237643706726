/* Navbar container */
.tab-navigation {
    background-color: #007bff;
    /* Bright Blue for the navbar */
    padding: 12px 0;
    /* Increased padding for top and bottom */
    position: relative;
    /* For positioning toggle button */
}

/* Toggle button for mobile view */
.tab-toggle {
    display: none;
    /* Hidden by default */
    background-color: transparent;
    /* Darker blue for button */
    color: #ffffff;
    /* White text */
    border: none;
    /* No border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 8px 10px;
    /* Padding for button */
    cursor: pointer;
    /* Pointer cursor */
    margin-bottom: 8px;
    /* Space below the toggle */
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
}

/* Container for the navigation tabs */
.tab-navigation-tabs {
    list-style: none;
    /* Remove default list item markers */
    padding: 0;
    /* Remove padding */
    margin: 0;
    /* Remove margin */
    display: flex;
    /* Flex display for horizontal alignment */
    flex-wrap: wrap;
    /* Allow items to wrap for small screens */
}

/* Styling for the navigation links */
.tab-navigation-link {
    background-color: transparent;
    /* Transparent background */
    color: #ffffff;
    /* White text */
    border: none;
    /* No border */
    border-radius: 5px;
    /* Rounded corners */
    padding: 8px 10px;
    /* Adjusted padding for a slightly smaller button */
    cursor: pointer;
    /* Pointer cursor */
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    /* Smooth transition */
    font-weight: 500;
    /* Medium font weight */
    font-size: 14px;
    /* Font size */
    text-decoration: none;
    /* No underline */
    display: flex;
    /* Ensure link uses flex for icon and text */
    align-items: center;
    /* Center items vertically within the link */
}

/* Styling for hover on the Home icon */
.tab-icon:hover {
    opacity: 0.8;
    /* Add a subtle hover effect */
}

/* Active state styling for the navigation links */
.tab-navigation-link.active {
    background-color: #0056b3;
    /* Active background color */
    color: white;
    /* Text color */
}

/* Highlighted effect for Feature Requests tab */
.tab-navigation-link.highlighted {
    box-shadow: 0 0 10px rgba(255, 255, 0, 0.8), 0 0 20px rgba(255, 255, 0, 0.6);
    /* Pulsing glow effect */
}

/* Hover effect styling for the navigation links */
.tab-navigation-link:hover {
    background-color: rgba(0, 87, 255, 0.7);
    /* Hover background color */
    color: #ffffff;
    /* Text color on hover */
}

/* Collapsible styles for mobile view */
.tab-navigation-tabs.collapsed {
    display: none;
    /* Hide tabs when collapsed */
}


/* Styling for individual navigation items (Desktop) */
.tab-navigation-item {
    margin: 0 8px;
    /* Default margin for desktop */
    display: flex;
    align-items: center;
}

/* Icon-specific styles for Home tab */
.tab-icon {
    width: 32px;
    /* Icon size */
    height: 32px;
    /* Icon size */
    vertical-align: middle;
    /* Aligns icon with text */
    margin: 0 8px;
    /* Space on both sides of the icon */
    cursor: pointer;
    /* Ensure it's clickable */
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
    .tab-navigation-tabs {
        flex-direction: column;
        /* Stack tabs vertically */
    }

    /* Maintain left margin and adjust vertical space between tab items for mobile */
    .tab-navigation-item {
        margin: 4px 0;
        /* Reduced vertical margin for closer spacing */
        margin-left: 8px;
        /* Maintain left margin */
    }

    .tab-toggle {
        display: block;
        /* Show the toggle button on mobile */
    }
}