/* Main container styling for the upload page */
.upload-page-body {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f7fa;
    /* Light Gray Background */
    padding: 60px;
}

/* Split the page into two sections */
.upload-page-container {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    width: 100%;
    height: 400px;
    /* Adjust height as needed */
}

/* Left section for logo */
.left-section {
    flex: 1;
    background-color: #eaeaea;
    /* Light background color for logo section */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
}

.left-section img {
    max-width: 350px;
    /* Increase the maximum width of the logo */
    width: 100%;
    /* Ensure it scales properly */
    height: auto;
    /* Keep the aspect ratio */
}

/* Right section for the upload form */
.right-section {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Heading styling */
.upload-page-heading {
    margin-bottom: 20px;
    font-size: 28px;
    color: #343a40;
}

/* Paragraph styling */
.upload-page-paragraph {
    margin-bottom: 20px;
    color: #666;
    font-size: 18px;
}

/* Styling for the file input */
.upload-page-file-input {
    width: 100%;
    padding: 12px;
    margin: 12px 0;
    border: 2px solid #e0e4e8;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 16px;
}

/* Focus styling for file input */
.upload-page-file-input:focus {
    border-color: #007bff;
    outline: none;
}

/* Button styling */
.upload-page-button {
    background-color: #007bff;
    color: white;
    padding: 14px;
    margin: 12px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
}

/* Hover effect for the button */
.upload-page-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Styling for warning messages */
.upload-page-warning-message {
    color: #e74c3c;
    margin-top: 10px;
    font-size: 14px;
}

/* Adjust for smaller screens */
@media (max-width: 768px) {
    .upload-page-container {
        flex-direction: column;
        height: auto;
    }

    .left-section,
    .right-section {
        width: 100%;
        padding: 20px;
    }

    .left-section {
        background-color: #f4f7fa;
    }
}

/* Styling for the Upload button */
.upload-page-button {
    background-color: #007bff;
    color: white;
    padding: 14px;
    margin: 12px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
}

/* Hover effect for the Upload button */
.upload-page-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

/* Styling for the Back button (secondary action) */
.upload-page-back-button {
    background-color: transparent;
    color: #007bff;
    padding: 10px;
    border: 2px solid #007bff;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    margin-top: 8px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Hover effect for the Back button */
.upload-page-back-button:hover {
    background-color: #007bff;
    color: white;
    border-color: #0056b3;
}


/* Talking Head Container */
.talking-head-container {
    position: fixed;
    /* Fixed position for visibility */
    bottom: 20px;
    /* Space from the bottom */
    right: 20px;
    /* Space from the right */
    display: flex;
    /* Flexbox for layout */
    align-items: center;
    /* Center items vertically */
    z-index: 1000;
    /* Ensure it appears above other content */
}

/* Styling for the talking head icon */
.talking-head-icon {
    width: 80px;
    /* Size of the talking head image */
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 50%;
    /* Circular image */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Shadow for depth */
    cursor: pointer;
    /* Pointer on hover */
    margin-left: 10px;
    /* Space between speech bubble and talking head */
}

/* Talking head text bubble styling */
.talking-head-section {
    background-color: #fff;
    /* White background for text bubble */
    border-radius: 10px;
    /* Rounded corners for the bubble */
    padding: 10px 15px;
    /* Padding for the text bubble */
    max-width: 250px;
    /* Max width for the chat bubble */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Shadow for bubble */
    z-index: 1000;
    /* Ensure it appears above other elements */
}

/* Chat text styling */
.talking-head-text {
    font-size: 14px;
    /* Font size for chat text */
    color: #333;
    /* Text color */
    line-height: 1.5;
    /* Improved line height for readability */
}


/* Speech bubble styling */
.speech-bubble {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    max-width: 250px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    margin-right: 15px;
    position: relative;
    font-size: 14px;
    color: #333;
}

/* Arrow pointing from bubble to head */
.speech-bubble:before {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

/* Slide-in animation for speech bubble */
.speech-bubble {
    animation: slideIn 0.5s ease-out;
}

@keyframes slideIn {
    0% {
        transform: translateX(50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Container for download buttons */
.download-buttons {
    margin-top: 8px;
    /* Reduced space above the buttons */
    display: flex;
    /* Use flexbox for layout */
    gap: 8px;
    /* Space between buttons */
}

/* Styling for download buttons */
.download-button {
    display: inline-block;
    /* Inline-block for button-like appearance */
    background-color: #007bff;
    /* Button color */
    color: white;
    /* Button text color */
    padding: 6px 10px;
    /* Reduced padding for a sleeker appearance */
    border-radius: 4px;
    /* Slightly smaller rounded corners */
    text-decoration: none;
    /* Remove underline */
    transition: background-color 0.3s, transform 0.2s;
    /* Smooth transition for hover effects */
    font-size: 12px;
    /* Smaller font size */
}

/* Hover effect for download buttons */
.download-button:hover {
    background-color: #0056b3;
    /* Darker shade on hover */
    transform: translateY(-1px);
    /* Slight lift effect */
}

/* Active state for download buttons */
.download-button:active {
    transform: translateY(1px);
    /* Slight press effect */
}

/* Disclaimer Section - Modern Styling */
.disclaimer-section {
    margin-top: 30px;
    padding: 20px;
    border-radius: 12px;
    background-color: #f7f9fc;
    /* Soft background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    /* Light shadow for depth */
    font-family: 'Roboto', sans-serif;
}

/* Main headings */
.disclaimer-section h2 {
    margin-bottom: 12px;
    font-size: 22px;
    color: #2c3e50;
    /* Darker modern color */
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid #dfe3e6;
    padding-bottom: 8px;
}

.disclaimer-section h3 {
    margin: 10px 0;
    font-size: 18px;
    color: #4a5568;
    /* Medium contrast color */
    font-weight: 500;
    text-align: left;
}

/* List styling with modern bullets */
.disclaimer-section ul {
    list-style: none;
    padding-left: 0;
    color: #4a4a4a;
    font-size: 15px;
}

/* Custom bullet points for the list */
.disclaimer-section ul li {
    position: relative;
    padding-left: 25px;
    /* Space for custom bullet */
    margin-bottom: 8px;
}

.disclaimer-section ul li:before {
    content: "•";
    /* Custom bullet */
    position: absolute;
    left: 0;
    color: #007bff;
    /* Brand or primary color */
    font-size: 20px;
    line-height: 1.2;
}

/* Example table styling */
.example-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 14px;
    color: #4a5568;
    border-radius: 8px;
    overflow: hidden;
    /* Rounds corners when content exceeds */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Header cells with modern look */
.example-table th {
    background-color: #f1f5f9;
    color: #2d3748;
    font-weight: 600;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
    text-align: left;
}

.example-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
}

/* Highlight on hover */
.example-table tr:hover {
    background-color: #f9fafb;
}