.chart-type-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* Space between toggle and chart */
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin: 0 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #007bff;
    /* Color when active */
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.chart-container {
    width: 80%;
    /* Default width */
    margin: 0 auto;
    /* Center the container */
    padding: 20px;
    /* Optional: add some padding for spacing */
    box-sizing: border-box;
    /* Ensure padding is included in width */
    min-height: calc(100vh - 60px);
    /* Ensure chart container does not overlap footer, adjust 60px to footer height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

/* Mobile styles */
@media (max-width: 768px) {
    .chart-container {
        width: 100%;
        /* Override to 100% for mobile */
        height: auto;
        /* Let content define height for mobile */
    }
}

.markdown-container {
    margin-top: 10px;
    /* Match the margin above */
    font-size: 16px;
    /* Keep the same font size */
    color: #333;
    /* Text color */
    background-color: #e0f7fa;
    /* Light background color for contrast */
    border-left: 4px solid #ddd;
    /* Vertical line to the left */
    padding: 10px 15px;
    /* Padding around the text */
    margin: 0 0 1em 0;
    /* Margin for spacing */
    border-radius: 5px;
    /* Optional: slightly rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: subtle shadow */
    overflow-y: auto;
    /* Allow scrolling if content overflows */
    max-height: 300px;
    /* Limit the height to prevent overlapping */
}

.markdown-container blockquote {
    color: #6c757d;
    /* Text color for quotes */
}

.range-slider {
    margin-bottom: 20px;
}

/* Footer placeholder to ensure proper spacing */
.footer {
    height: 60px;
    /* Adjust to your actual footer height */
    background-color: #f1f1f1;
    text-align: center;
    line-height: 60px;
    /* Vertically center text */
    position: absolute;
    bottom: 0;
    width: 100%;
}

.thumb-label {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    padding: 3px 6px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    color: #333;
    white-space: nowrap;
}