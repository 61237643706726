/* Main container for legal documents */
.legal-documents {
    font-family: 'Roboto', sans-serif;
    /* Font family for consistency */
    margin: 40px auto;
    /* Center the content with more vertical space */
    padding: 30px;
    /* Increased padding for more breathing room */
    max-width: 800px;
    /* Set max width for readability */
    background-color: #ffffff;
    /* White background for contrast */
    border-radius: 12px;
    /* Rounded corners for a modern look */
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

/* Sticky navigation bar styling */
.sticky-nav {
    position: sticky;
    /* Keep it at the top */
    top: 0;
    /* Stick to the top */
    background-color: #ffffff;
    /* Background color */
    padding: 15px 20px;
    /* Padding around the text */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* Softer shadow for depth */
    z-index: 1000;
    /* Ensure it's above other content */
    border-bottom: 2px solid #007bff;
    /* Border to match theme */
}

/* Navigation link styling */
.sticky-nav a {
    text-decoration: none;
    /* Remove underline */
    color: #007bff;
    /* Main theme color */
    font-weight: bold;
    /* Make the text bold */
    margin-right: 15px;
    /* Spacing between links */
    transition: color 0.3s;
    /* Smooth transition for hover */
}

.sticky-nav a:hover {
    color: #0056b3;
    /* Darker shade on hover */
}

/* Section heading styling */
.legal-documents h2 {
    font-size: 28px;
    /* Increased size for primary headings */
    margin-top: 30px;
    /* Space above the heading */
    margin-bottom: 15px;
    /* Space below the heading */
    color: #2c3e50;
    /* Dark color for text */
    border-bottom: 2px solid #007bff;
    /* Underline effect for emphasis */
    padding-bottom: 10px;
    /* Padding below heading for separation */
}

/* Subheading styling */
.legal-documents h3 {
    font-size: 22px;
    /* Size for subheadings */
    margin-top: 25px;
    /* Space above subheadings */
    margin-bottom: 10px;
    /* Space below subheadings */
    color: #34495e;
    /* Slightly lighter color for subheadings */
}

/* Paragraph styling */
.legal-documents p {
    font-size: 16px;
    /* Base font size */
    color: #555;
    /* Darker gray for text */
    line-height: 1.7;
    /* Improved readability */
    margin-bottom: 20px;
    /* Increased space below paragraphs */
}

/* List styling */
.legal-documents ul {
    margin-left: 20px;
    /* Indent unordered lists */
    margin-bottom: 20px;
    /* Space below lists */
}

.legal-documents li {
    font-size: 16px;
    /* Consistent font size for list items */
    color: #555;
    /* Darker gray for list item text */
    margin-bottom: 10px;
    /* Space between list items */
}

/* Anchor link styling for privacy and TOS */
.legal-documents a {
    color: #007bff;
    /* Primary color for links */
    text-decoration: none;
    /* Remove underline */
    font-weight: bold;
    /* Bold for emphasis */
}

.legal-documents a:hover {
    text-decoration: underline;
    /* Underline on hover for interaction */
}

/* Strong text styling */
.legal-documents strong {
    color: #2c3e50;
    /* Dark color for emphasized text */
}

/* Code or preformatted text styling */
.legal-documents code {
    background-color: #f1f1f1;
    /* Light gray background for code */
    padding: 2px 4px;
    /* Padding for code */
    border-radius: 4px;
    /* Rounded corners for code */
    font-family: 'Courier New', Courier, monospace;
    /* Monospace font for code */
}