/* Container for the data table */
.data-table-container {
    overflow-x: auto;
    max-height: 700px;
    /* Increased max height for a taller view */
    overflow-y: auto;
    /* Enable vertical scrolling */
    margin-bottom: 20px;
}

/* Styling for the main table */
.data-table-table {
    width: 100%;
    border-collapse: collapse;
}

/* Styling for table headers and cells */
.data-table-th,
.data-table-td {
    border: 1px solid #ddd;
    padding: 12px 16px;
    /* Increased padding for a more spacious look */
    text-align: center;
    cursor: default;
}

/* Make the top headers sticky */
.data-table-th {
    position: sticky;
    top: 0;
    background-color: #f4f4f4;
    /* Prevents content from being visible under the header */
    z-index: 2;
    /* Ensures headers stay above other elements */
}

/* Make the first column sticky */
.data-table-th.row-header {
    position: sticky;
    left: 0;
    background-color: #f8f9fa;
    /* Background color for row headers */
    z-index: 3;
    /* Higher z-index to keep the row headers above table cells */
}

/* Empty header cell styling */
.data-table-th.empty-header {
    border: none;
    z-index: 4;
    /* Ensure it's always on top */
    left: 0;
    /* Keep this cell aligned with sticky row headers */
}

/* Highlighted column headers */
.data-table-th.highlighted {
    background-color: #f0f8ff;
}

/* Highlighted cells */
.data-table-td.highlighted {
    background-color: #fff3cd;
}

/* Dimmed cells and headers */
.data-table-td.dimmed,
.data-table-th.dimmed {
    opacity: 0.5;
    background-color: #f0f0f0;
}

/* Styling for headers in the data table */
.data-table .data-table-th {
    background-color: #f4f4f4;
}

/* Styling for dimmed cells in the data table */
.data-table .data-table-dimmed {
    background-color: #f0f0f0;
}