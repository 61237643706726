.feedback-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.feedback-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #007bff;
    color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 300px;
    text-align: center;
}

.feedback-modal button {
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-top: 15px;
    /* Added margin for spacing */
}

.feedback-modal button:hover {
    background-color: rgba(0, 87, 255, 0.8);
}

.feedback-modal p {
    margin: 10px 0;
    line-height: 1.5;
}

.feedback-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center horizontally */
    margin-top: 15px;
    /* Added margin for spacing */
    margin-bottom: 15px;
    /* Added margin for spacing */
    text-align: center;
    /* Center text */
}

.feedback-checkbox input {
    margin-right: 8px;
    /* Space between checkbox and label text */
}