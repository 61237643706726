/* Main container styling for the App component */
.app-container {
  text-align: center;
}

/* Logo styling */
.app-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Animation styling for the logo when motion preference is set */
@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

/* Header styling for the main app section */
.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Link styling */
.app-link {
  color: #61dafb;
}

/* Keyframe animation for spinning the logo */
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}